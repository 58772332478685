<template>
  <div>
    <busy :visible="loading" />
    <div v-if="!loading">
      <div class="row">
        <div class="col-auto ms-auto">
          <div v-if="user.calendar_service == 'google'">
          <span
              v-if="calendarSettings && calendarSettings.check"
              class="badge bg-primary"
          >
            Connected to Google
          </span>
            <span
                v-else-if="calendarSettings && !calendarSettings.check"
                class="badge bg-danger"
            >
            Access Token Expired
          </span>
            <span v-else class="badge bg-warning">Not Connected</span>
          </div>
        </div>
      </div>
      <div v-if="!user.calendar_service">
        <calendar-setup :user="user"></calendar-setup>
      </div>
      <div v-else>
        <div v-if="this.user.calendar_service === 'google'">
          <div class="row">
            <div class="col" v-if="this.calendarSettings.check">
              <div class="row mb-4">
                <div class="col">
                  <p>
                    You are currently connected using your
                    <kbd>{{ this.calendarSettings.user }}</kbd> Google Account
                  </p>
                </div>
              </div>

              <hr />

              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-auto">
                      <div class="custom-control custom-switch">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="emailSync"
                          v-model="eventSync"
                          :checked="eventSync != null && eventSync != 0"
                          @change="toggleEventSync"
                        />
                        <label class="custom-control-label" for="emailSync"
                          >Events Sync</label
                        >
                      </div>
                    </div>
                    <div
                      class="col-auto ms-4"
                      v-if="eventSync"
                      style="border-right: 1px dashed #edf2f2"
                    >
                      <div class="h5 mb-3">Sync Settings</div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="syncDirection"
                          @change="updateEventSync"
                          value="bidirectional"
                          id="bidirectional"
                        />
                        <label class="form-check-label" for="bidirectional">
                          Sync Both Ways
                        </label>
                      </div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="syncDirection"
                          @change="updateEventSync"
                          value="import"
                          id="import"
                        />
                        <label class="form-check-label" for="fromGoogle">
                          Import from Google
                        </label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          v-model="syncDirection"
                          @change="updateEventSync"
                          value="export"
                          id="export"
                        />
                        <label class="form-check-label" for="toGoogle">
                          Export to Google
                        </label>
                      </div>
                    </div>
                    <div class="col-auto" v-if="eventSync">
                      <div class="h5 mb-3">Sync Event Types</div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="checkedEventTypes"
                          @change="updateEventSync"
                          value="event"
                          id="event"
                        />
                        <label class="form-check-label" for="event">
                          Events
                        </label>
                      </div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="checkedEventTypes"
                          @change="updateEventSync"
                          value="appointment"
                          id="appointment"
                        />
                        <label class="form-check-label" for="appointment">
                          Client Appointments
                        </label>
                      </div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="checkedEventTypes"
                          @change="updateEventSync"
                          value="consultation"
                          id="consultation"
                        />
                        <label class="form-check-label" for="consultation">
                          Client Consultations
                        </label>
                      </div>
                      <div class="form-check mb-3">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="checkedEventTypes"
                          @change="updateEventSync"
                          value="task"
                          id="task"
                        />
                        <label class="form-check-label" for="task">
                          Tasks
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
              <div class="row mt-4 mb-4">
                <div class="col">
                  <p>
                    <small
                      >Disconnecting from this service will remove any events
                      synced from Google, you may re-activate this service at
                      anytime.</small
                    >
                  </p>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <a
                    @click="syncEvents"
                    class="btn btn-outline-primary btn-sm"
                    >Sync Events</a
                  >
                </div>

                <div class="col text-end">
                  <button
                      @click="disconnectGoogle"
                    class="btn btn-outline-danger btn-sm"
                  >
                    Disconnect from Google
                  </button>
                </div>
              </div>
            </div>
            <div class="col" v-else>
              <p class="alert alert-danger">
                <i class="far fa-exclamation-circle me-1" />
                Your Google Calendar integration has expired.
                <br />
                <br />
                <i class="far fa-info-circle me-1" />
                This is usually the result of a Google password change. Please
                re-grant access to the Swandoola app below.
              </p>
              <div class="row mb-3">
                <div class="col-6 mx-auto text-center">
                  <img
                    src="@/assets/images/providers/google/btn_google_signin_dark_normal_web.png"
                    class="cursor-pointer"
                    @click="signInWithGoogleCalendar"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import CalendarSetup from "@/views/areas/settings/areas/integrations-partials/calendar-partials/CalendarSetup";
export default {
  props: [],
  data() {
    return {
      loading: true,
      eventSync: null,
      syncDirection: null,
      calendarSettings: { user: "", check: "" },
      checkedEventTypes: null
    };
  },
  methods: {
    getDefaultEventTypes() {
      const events = ["consultation", "appointment", "task", "event"];
      var defaults = events;
      if (this.user.sync_settings && this.user.sync_settings.event_sync_types) {
        return JSON.parse(this.user.sync_settings.event_sync_types);
      }
      return defaults;
    },
    toggleEventSync() {
      if (this.eventSync) {
        this.syncDirection = "bidirectional";
        this.checkedEventTypes = this.getDefaultEventTypes();
      } else {
        this.syncDirection = null;
        this.checkedEventTypes = [];
      }
      this.updateEventSync();
    },
    getCalendarSettings() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/practitioners/fetch-calendar-settings",
          {}
        )
        .then(({ data }) => {
          this.calendarSettings = data;
          this.loading = false;
        });
    },
    updateEventSync() {
      this.$axios
        .post(
          process.env.VUE_APP_API_URL +
            "/settings/integrations/update-event-sync",
          {
            event_sync: this.eventSync,
            event_types: this.checkedEventTypes,
            sync_direction: this.syncDirection
          }
        )
        .then(({ data }) => {
          this.$EventBus.$emit("refreshUser");
          this.$EventBus.$emit("alert", data);
        });
    },
    syncEvents() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/oauth/sync-events")
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    disconnectGoogle() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/oauth/google-calendar/logout")
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.$EventBus.$emit("refreshUser");
        });
    },
    signInWithGoogleCalendar() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/oauth/google-calendar")
        .then(({ data }) => {
          window.location = data;
        });
    }
  },
  mounted() {
    this.$EventBus.$emit("refreshUser");
    this.checkedEventTypes = this.getDefaultEventTypes();
    this.eventSync = this.user.sync_settings
      ? this.user.sync_settings.event_sync
      : false;
    this.syncDirection = this.user.sync_settings
      ? this.user.sync_settings.event_sync_direction
      : "bidirectional";
    this.getCalendarSettings();
  },
  computed: {
    path() {
      return this.$stash.path;
    },
    user() {
      return this.$store.getters['auth/user'];
    }
  },
  components: {
    CalendarSetup
  }
};
</script>

<style>
</style>
