<template>
<div>
  <busy :visible="loading" />
  <div v-if="!loading" class="card border-0 mb-3">
    <div class="card-header">
      <div class="row">
        <div class="col">Zoom Integration</div>
      </div>
    </div>
    <div class="card-body" v-if="!integrated">
      <button class="btn btn-info" @click="requestSignIn">Start Integration</button>
      <p v-if="waitingForSignin">Authenticating... please wait. You can refresh this page to check the status.</p>
    </div>
    <div class="card-body" v-else>
      <div class="alert alert-success" role="alert">
        You have successfully integrated your Zoom account.
      </div>
      <div class="card my-2">
        <div class="card-header">
          How To Use
        </div>
        <div class="card-body">
          <ul class="p-2">
            <li>Open up a video consultation.</li>
            <li>Next to the method dropdown you will now see a 'use zoom' toggle.</li>
            <li>Click the toggle, this will create a zoom meeting for this consultation.</li>
            <li>Follow the zoom information guidence on the consultation page.</li>
            <br/>
            <li><small><i>Note: Unselecting the toggle will transform it back to a Swandoola Video meeting</i></small></li>
          </ul>
        </div>
      </div>
      <button class="btn btn-danger" @click="uninstallText = true">Remove</button>
      <div v-if="uninstallText">
        <p>Please uninstall the integration via Zoom</p>
        <ul>
          <li>Goto zoom by <a href="https://marketplace.zoom.us/user/installed">clicking here</a></li>
          <li>Locate the 'Swandoola Meetings' App and click uninstall</li>
          </ul>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      integrated: false,
      loading: true,
      waitingForSignin: false,
      uninstallText: false,
    };
  },
  methods: {
    requestSignIn() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/zoom/request-signin")
        .then(({ data }) => {
          console.log(data);
          if (data.url) {
            window.open(data.url, "_blank");
            this.waitingForSignin = true;
            setTimeout(function () { location.reload(); }, 10000);
          }
        });
    },
    getZoomIntegration() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/zoom/integration")
        .then(({ data }) => {
          if (data.integration) {
            this.integrated = true;
          }
          this.loading = false;
        });
    },
    removeZoomIntegration() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/zoom/remove-integration")
        .then(({ data }) => {
          if (data.integration === false) {
            this.integrated = false;
          }
          this.$EventBus.$emit("alert", data);
        });
    },
    createZoom() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/zoom/create-meeting", {
          start_time: moment().toString(),
          topic: 'test meeting',
          agenda: 'just a test'
        })
        .then(({ data }) => {
          console.log(data);
          if (data.integration) {
            this.integrated = true;
          }
        });
    }
  },
  mounted() {
    this.getZoomIntegration();
  },
  computed: {
    path() {
      return this.$stash.path;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
};
</script>

<style>
</style>
