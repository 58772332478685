<template>
  <div>
    <div class="row mb-4">
      <div class="col">
        <p>Connect with your external calendar service from here, this will sync your external calendar with Swandoola. This allows
          you to view personal calendar events within Swandoola Calendar and prevent bookings during your personal events.</p>
      </div>
    </div>
    <div class="row" v-if="!service">
      <div class="col-6 mx-auto text-center">
        <p>Select your calendar provider to get started:</p>
        <button class="btn btn-outline-primary w-100 mb-3 shadow-sm" @click="service='google'">
            <h6 class="d-inline">Google Calendar</h6>
        </button>
      </div>
    </div>

    <div v-if="service==='google'">
      <div class="row mb-3">
        <div class="col-6 mx-auto text-center">
          <img src="@/assets/images/providers/google/btn_google_signin_dark_normal_web.png"
               class="cursor-pointer"
               @click="signInWithGoogleCalendar" />
        </div>
      </div>
    </div>

  </div>
</template>



<script>
export default {
  props: ["path", "user"],
  data() {
    return {
      service: null
    };
  },
  methods: {
    signInWithGoogleCalendar(){
      this.$axios.get(process.env.VUE_APP_API_URL + "/oauth/google-calendar")
          .then(({ data }) => {
            window.location = data;
          });
    },
  },
  computed: {
  },
  mounted() {
  },
  components: {
  }
};
</script>

<style>
</style>
