<template>
  <div class="container-fluid">
    <div class="row">

      <!-- Tab cards -->
      <div class="col">
        <h4 class="fw-bold text-primary">Email Integration</h4>
        <email-integration></email-integration>

        <h4 class="fw-bold text-primary">Calendar Integration</h4>
        <calendar-integration></calendar-integration>

        <h4 class="fw-bold text-primary">Zoom Integration</h4>
        <zoom-integration v-if="user.id === 1651 || user.id === 1244" />
      </div>
    </div>
  </div>
</template>

<script>

import CalendarIntegration from "@/views/areas/settings/areas/integrations-partials/CalendarIntegration";
import EmailIntegration from "@/views/areas/settings/areas/integrations-partials/EmailIntegration";
import ZoomIntegration from "./integrations-partials/ZoomIntegration";

export default {
  props: [],
  data() {
    return {};
  },
  computed: {
    path() {
      return this.$stash.path;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  components: {
    CalendarIntegration,
    EmailIntegration,
    ZoomIntegration,

  },
};
</script>

<style></style>
